import { SNAPSEARCHER_APP_ID } from '@snapbrains/snapav/src/avantlink';

export const AppName = 'SnapRemix';
export const AppDescription = `${AppName} is a free tool for AvantLink affiliates that makes adding search to your website or blog simple.`;
export const AppEmail = 'support@snaplinker.com';
export const AppId = SNAPSEARCHER_APP_ID;

export const GoogleAnalyticsId = null; // 'G-2DEQ4TYGY9' can be used for SnapSearcher but I'm turning it off until we get the accept cookies banner in place;

export const AppUrl = 'https://www.snapremix.com';

// Rust based settings server
// export const SettingsApiUrl = 'https://settings.snapslinger.com';
// export const JwtAuthApiUrl = 'https://settings.snapslinger.com';

// export const SettingsApiUrl = 'http://127.0.0.1:8080';
// export const JwtAuthApiUrl = 'http://127.0.0.1:8080';

// Cloudflare worker based settings
// Production
// export const SettingsApiUrl = 'https://settings.snapsearcher.com';
// export const JwtAuthApiUrl = 'https://settings.snapsearcher.com';

export const SettingsApiUrl = 'http://127.0.0.1:8787';
export const JwtAuthApiUrl = 'http://127.0.0.1:8787';

export const SignUpFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSfFiTI5ovJ2UV1xZTJ6qXLJ9JhCjxy187khJ236xGmgUbBBPg/viewform?embedded=true&entry.1721758524=I+agree+to+receive+snapbase+updates+via+email&entry.1721758524=I+wish+to+receive+updates+about+other+Snap+related+tools+(ie+SnapLinker,+SnapDealist)';
